import React, { useEffect, useState } from "react";
import "./App.scss";
import { rem } from "./common/scss/rem";
import IndexPage from "./IndexPage";

const App: React.FC = () => {
	const [refresh, setRefresh] = useState<number>(0);
	useEffect(() => {
		document.title = (window as any).CONFIG.systemTitle;
		rem();
		window.addEventListener("resize", () => {
			rem();
		});
	}, []);
	return <IndexPage />;
};

export default App;
