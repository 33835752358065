import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import { observer } from "mobx-react";
import React from "react";
import { isPhone } from "src/common/scss/rem";
import MobilePage from "./MobilePage";
import PcPage from "./PcPage";

const IndexPage: React.FC = () => {
	return <ConfigProvider locale={zhCN}>{isPhone() ? <MobilePage /> : <PcPage />}</ConfigProvider>;
};

export default observer(IndexPage);
