import React, { useEffect } from "react";
import classNames from "./index.module.scss";

interface IProp {
	className?: string;
}
const AdressMap: React.FC<IProp> = (props) => {
	useEffect(() => {
		const scale = new AMap.Scale({
				visible: true,
			}),
			toolBar = new AMap.ToolBar({
				visible: true,
				position: {
					top: "110px",
					right: "40px",
				},
			}),
			controlBar = new AMap.ControlBar({
				visible: true,
				position: {
					top: "10px",
					right: "10px",
				},
			}),
			overView = new AMap.HawkEye({
				visible: true,
			});
		const map = new AMap.Map("address", {
			resizeEnable: true, //是否监控地图容器尺寸变化
			zoom: 16, //初始化地图层级
			// viewMode: "3D", // 地图模式
			// terrain: true, // 开启地形图
		});
		map.addControl(scale);
		map.addControl(toolBar);
		map.addControl(controlBar);
		map.addControl(overView);
		const placeSearch = new AMap.PlaceSearch({
			city: "beijing", // 兴趣点城市
			citylimit: true, //是否强制限制在设置的城市内搜索
			pageSize: 10, // 单页显示结果条数
			children: 0, //不展示子节点数据
			pageIndex: 1, //页码
			extensions: "base", //返回基本地址信息
		});
		//详情查询
		placeSearch.getDetails("B0HGRUMMC6", function (status: string, result: any) {
			if (status === "complete" && result.info === "OK") {
				placeSearch_CallBack(result);
			}
		});
		const infoWindow = new AMap.InfoWindow({
			autoMove: true,
			offset: { x: 0, y: -30 },
		});
		//回调函数
		function placeSearch_CallBack(data: { poiList: { pois: any } }) {
			const poiArr = data.poiList.pois;
			//添加marker
			const marker = new AMap.Marker({
				map: map,
				position: poiArr[0].location,
			});
			map.setCenter(marker.getPosition());
			infoWindow.setContent(createContent(poiArr[0]));
			infoWindow.open(map, marker.getPosition());
		}
		function createContent(poi: {
			name: string;
			address: string;
			tel: string;
			location: { lng: any; lat: any };
		}) {
			//信息窗体内容
			const s = [];
			s.push("<b>名称：" + poi.name + "</b>");
			s.push("地址：" + poi.address);
			s.push("电话：" + poi.tel);
			map.center = [poi?.location?.lng ?? 116.397428, poi?.location?.lat ?? 39.90923];
			// s.push("类型：" + poi.type);
			return s.join("<br>");
		}
	}, []);
	return (
		<div className={`${props.className}`}>
			<div id={"address"} className={`${classNames.address}`}></div>
		</div>
	);
};
export default AdressMap;
