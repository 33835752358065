import { observer } from "mobx-react";
import React from "react";
import InfoBlock from "src/Component/InfoBlock";
import Block from "src/Component/InfoBlock/Block";

const Advantage: React.FC = () => {
	return (
		<InfoBlock title={"公司优势"} titleEng={"COMPANY PAULIFICTION"}>
			<div>
				<Block title={"一、规格齐全、搭配比例合理，供货迅速"}>
					<p>
						公司可供应管材5大类别260多种规格，管件500多种规格，是国内管材、管件最为齐全的专业工厂。公司产量庞大，成品存货量充足，供应准确快速，可充分适合各种工程配管的需要。
					</p>
				</Block>

				<Block title={"二、设备优良、工艺先进、质量可靠"}>
					<p>
						我们公司拥有国际先进的生产加工设备及国家级的实验室设备并被国家塑料质量检测中心认可。
						生产设备方面：我们的生产混料设备为全自动全封闭电脑控制系统，保证了配料的均匀稳定性。加工押出设备亦引进国际先进水平之辛辛那提麦克马隆公司双螺杆押出生产线，全电脑自动化控制，一次成型、无添加任何人为因素，产品质量优良稳定。
					</p>
					<p>
						检测设备方面：
						我们的检测设备已达到国家实验室标准，并得到国家塑料检测中心的认可。鉴于公司近50年来形成这严谨的质量控制作风，基于“追根究底，止于至善”之经营理念，自建厂初期便投巨资近2000万元购进国外全套检测设备，对产品进行检验改良配方。所有试验设备之检测精度均高于国内同类。且部分实验设备除国家塑料质量检测中心外，无任何一家有此高精度之设备。
						我们针对生产所需原料及产制过程中，采用层层检测之方式，彻底杜绝不合格产品进入市场。公司每批管材出厂前均需检测并附出厂检验报告始可出厂，并将所有检测报告存盘存查。
					</p>
				</Block>

				<Block title={"三、环保配方、国内首创"}>
					<p>
						公司产品质量标准除完全按照GB国家标准外，部分产品更采用ISO国际规范。公司为国内第一家采用有机锡配方代替传统铅系配方的塑料管生产企业，避免了重金属析出而带来的水质污染，可完全符合国家饮用水卫生指标及蒸馏水卫生指标，充分体现环保建材的优势。
					</p>
				</Block>
				<Block title={"四、从施工、设计入手，注重服务"}>
					<p>
						公司在营业推广过程中，建立完善的售前、售中、售后服务体系，从设计、施工入手，向广大工程用户提供施工技术培训服务，并对各工程实施追踪，解除用户顾虑，深获用户单位信赖。
					</p>
				</Block>
			</div>
		</InfoBlock>
	);
};
export default observer(Advantage);
