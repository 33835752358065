import { observer } from "mobx-react";
import React from "react";
import { DESIGN } from "src/Config/common";
import classNames from "./index.module.scss";

const Footer: React.FC = () => {
	return (
		<footer className={classNames.footer} style={{ height: DESIGN.Lfooter }}>
			<a href={"https://beian.miit.gov.cn"} target={"_blank"} rel="noreferrer">
				<span>浙ICP备2021034375号</span>
			</a>
		</footer>
	);
};
export default observer(Footer);
