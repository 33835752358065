import { observer } from "mobx-react";
import React from "react";
import classNames from "./index.module.scss";

interface IProp {
	title: string;
}
const Block: React.FC<IProp> = (props) => {
	return (
		<div className={classNames.block}>
			<h2>{props.title}</h2>
			{props.children}
		</div>
	);
};
export default observer(Block);
