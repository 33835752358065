export const rem = (): void => {
	document.documentElement.style.fontSize =
		((document.documentElement.clientWidth * 16) / 1980) * 10 + "px";
	console.log(((document.documentElement.clientWidth * 16) / 1980) * 10);
};

export const isPhone = (): boolean => {
	const ratio = document.body.clientWidth / document.body.clientHeight;
	const isMobile = ratio <= 0.8;
	const agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
	const info = navigator.userAgent;
	const isPhone = agents.some((agent) => info.includes(agent));
	return isMobile && isPhone;
};

export const Client = {
	width: document.documentElement.clientWidth,
	height: document.documentElement.clientHeight,
};
