import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import classNames from "./index.module.scss";

interface IProp {
	title: string;
	menus: {
		name: string;
		str: string;
	}[];
	default?: boolean;
}
const SideBar: React.FC<IProp> = (props) => {
	const hashHistory = useHistory();

	const parent = hashHistory.location.pathname?.split("/")?.[1] ?? "";
	const active = hashHistory.location.pathname?.split("/")?.[2];
	if (!active && props.default) {
		hashHistory.push(`/` + parent + "/" + props.menus[0].str);
	}

	return (
		<div className={classNames.sidebar}>
			<div className={classNames.title}>
				<h2>{props.title}</h2>
			</div>
			<div className={classNames.menus}>
				{props.menus.map((menu) => {
					return (
						<Link
							to={`/` + parent + "/" + menu.str}
							key={menu.str}
							className={`${classNames.menu} ${
								active === menu.str && classNames.active
							}`}
						>
							<div className={classNames.name}>{menu.name}</div>
						</Link>
					);
				})}
			</div>
			<div className={classNames.end}>
				<div className={classNames.endName}>{`服务热线`}</div>
				<h2 className={classNames.tel}>{`0571-89993478`}</h2>
			</div>
		</div>
	);
};
SideBar.defaultProps = {
	default: true,
};
export default observer(SideBar);
