import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { DESIGN } from "src/Config/common";
import { tabs } from "src/Config/tabs";
import HeaderStore from "./HeaderStore";
import classNames from "./index.module.scss";
// const tabs = [
// 	{ key: "/home", name: "首页" },
// 	{ key: "/about", name: "关于我们" },
// 	{ key: "/case", name: "工程案例" },
// 	{ key: "/product", name: "产品中心" },
// 	{ key: "/cooperation", name: "合作厂商" },
// 	{ key: "/skill", name: "技术指导" },
// 	{ key: "/news", name: "相关资讯" },
// 	{ key: "/contact", name: "联系我们" },
// ];
interface IProp {
	className?: string;
}
const Header: React.FC<IProp> = (props) => {
	useEffect(() => {
		HeaderStore.setActive(tabs[0].path);
	}, []);

	return (
		<header
			className={`${classNames.header} ${props.className}`}
			style={{ height: `${DESIGN.Ltitle}` }}
		>
			<div className={classNames.iconTitle} style={{ height: `${DESIGN.titleImg}` }}>
				<img src="/img/Header/title.jpg" alt=""></img>
			</div>
			<div className={classNames.tabs} style={{ height: `${DESIGN.titleSub}` }}>
				{tabs.map((tab, idx) => {
					return (
						<Link
							to={tab.path}
							key={idx}
							className={`${classNames.tab} ${
								HeaderStore.active === tab.path ? classNames.active : ""
							}`}
							onClick={() => {
								HeaderStore.setActive(tab.path);
							}}
						>
							{tab.name}
						</Link>
					);
				})}
			</div>
		</header>
	);
};
export default observer(Header);
