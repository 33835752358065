import { makeAutoObservable } from "mobx";

class HeaderStore {
	constructor() {
		makeAutoObservable(this);
	}
	active = "";
	setActive(path: string) {
		this.active = path;
	}
}
export default new HeaderStore();
