import { observer } from "mobx-react";
import React from "react";
import classNames from "./index.module.scss";

interface IProp {
	title: string;
	titleEng: string;
	fenge?: boolean;
}
const InfoBlock: React.FC<IProp> = (props) => {
	return (
		<div className={classNames.infoBlock}>
			<div className={classNames.infoBlockTitle}>
				<div className={classNames.title}>{props.title}</div>
				{/* <div className={classNames.titleEng}>{props.titleEng}</div> */}
				{props.fenge !== false && <div className={classNames.fenge}></div>}
			</div>
			{props.children}
		</div>
	);
};
export default observer(InfoBlock);
