import { observer } from "mobx-react";
import React from "react";
import Banner from "src/Component/Banner";
import { ImageConfig } from "./ImageConfig";

const Home: React.FC = () => {
	return (
		<Banner
			imgsUrlConfig={ImageConfig.map((config) => {
				return {
					imgUrl: `/img/Home/Compress/${config.imgName}.jpg`,
					taobaoUrl: config.taobaoUrl,
				};
			})}
		/>
	);
	// 	<div className={classNames.home}>
	// 		<Banner
	// 			imgsUrl={homeImgName.map((img) => {
	// 				return `/img/Home/${img}.jpg`;
	// 			})}
	// 		/>
	// 		{/* <Business /> */}
	// 		{/* <Sub></Sub>
	// 		<HDetial></HDetial> */}
	// 	</div>
	// );
};
export default observer(Home);
