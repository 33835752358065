import { observer } from "mobx-react";
import React, { useState } from "react";
import { Route, useHistory } from "react-router";
import Company from "src/Component/Company";
import Content from "src/Component/Content";
import InfoBlock from "src/Component/InfoBlock";
import Productdetail from "src/Component/Productdetail";
import SideBar from "src/Component/SideBar";
import classNames from "./index.module.scss";

const menus = [
	{
		name: "华亚",
		str: "huaya",
		component: <Productdetail productKey="huaya"></Productdetail>,
	},

	{
		name: "三佑",
		str: "sanyou",
		component: <Productdetail productKey="sanyou"></Productdetail>,
	},
	{ name: "阀门", str: "fm", component: <Productdetail productKey="FM"></Productdetail> },

	{ name: "PPR", str: "ppr", component: <Productdetail productKey="PPR"></Productdetail> },
];
const Product: React.FC = () => {
	const [currentProduct, setCurrentProduct] = useState<string>();

	const hashHistory = useHistory();

	return (
		<div className={classNames.product}>
			<Company></Company>
			<Content>
				<SideBar
					title="产品展示"
					menus={menus}
					key={Math.random() * Math.random()}
				></SideBar>
				<InfoBlock title={"产品系列"} titleEng={"PRODUCTS SERIES"} fenge={false}>
					{menus.map((item, idx) => {
						return (
							<Route
								path={`${"/product"}/${item.str}`}
								component={() => item.component ?? null}
								key={idx}
								exact
							></Route>
						);
					})}
					{/* <Breadcrumb>
						<Breadcrumb.Item>
							<Link
								to={PATH_ENUM.首页}
								onClick={() => {
									HeaderStore.setActive(PATH_ENUM.首页);
								}}
							>
								{PATHNAME_ENUM["/home"]}
							</Link>
						</Breadcrumb.Item>
						{hashHistory.location.pathname.split("/")[1] === "product" && (
							<Breadcrumb.Item>
								<Link to={PATH_ENUM.产品中心}>{PATHNAME_ENUM["/product"]}</Link>
							</Breadcrumb.Item>
						)}
						{hashHistory.location.pathname.split("/")[2] && (
							<Breadcrumb.Item>
								{
									(PATHNAME_ENUM as any)[
										"/" + hashHistory.location.pathname.split("/")[2]
									]
								}
							</Breadcrumb.Item>
						)}
					</Breadcrumb>
					{hashHistory.location.pathname === "/product" && (
						<Productdetail
							products={[
								{ src: ``, img: "/img/Home/1.jpg", title: "华亚", key: "huaya" },
								{ src: "", img: "/img/Home/1.jpg", title: "三佑", key: "sanyou" },
								{ src: "", img: "/img/Home/1.jpg", title: "PPR", key: "ppr" },
							]}
							onClick={(value) => {
								setCurrentProduct(value);
								hashHistory.push(`/product/${value}`);
							}}
						></Productdetail>
					)}
					{["huaya", "sanyou", "ppr"].map((item, idx) => {
						return (
							<Route
								path={`${"/product"}/${item}`}
								component={() => test}
								key={idx}
								exact
							></Route>
						);
					})} */}
					{/* {currentProduct && (
					<Productdetail
						products={
							ProductConfig.find((product) => product.key === currentProduct)?.list
						}
					></Productdetail>
				)} */}
				</InfoBlock>
			</Content>
		</div>
	);
};
export default observer(Product);
