import { observer } from "mobx-react";
import React from "react";
import { Route } from "react-router";
import Company from "src/Component/Company";
import Content from "src/Component/Content";
import SideBar from "src/Component/SideBar";
import Advantage from "./Advantage";
import classNames from "./index.module.scss";
import Introduction from "./Introduction";
const menus = [
	{
		name: "公司简介",
		str: "introduction",
		component: <Introduction />,
	},
	{
		name: "公司优势",
		str: "advantage",
		component: <Advantage />,
	},
	{ name: "公司展示", str: "exhibition", component: null },
];
const About: React.FC = () => {
	return (
		<div className={classNames.about}>
			<Company></Company>
			<Content>
				<SideBar
					title="关于我们"
					menus={menus}
					key={Math.random() * Math.random()}
				></SideBar>
				<div className={classNames.text}>
					{menus.map((item, idx) => {
						return (
							<Route
								path={`${"/about"}/${item.str}`}
								component={() => item.component ?? null}
								key={idx}
								exact
							></Route>
						);
					})}
				</div>
			</Content>
		</div>
	);
};
export default observer(About);
