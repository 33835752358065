import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route } from "react-router";
import { HashRouter } from "react-router-dom";
import { DESIGN } from "src/Config/common";
import { tabs } from "src/Config/tabs";
import Footer from "src/Page/Footer";
import Header from "src/Page/Header";
import classNames from "./index.module.scss";
const PcPage: React.FC = () => {
	return (
		<div className={classNames.indexPage}>
			<HashRouter>
				<Header className={classNames.header}></Header>
				<div className={classNames.content} style={{ minHeight: DESIGN.Lcontent }}>
					<Redirect exact to="/home" path="/"></Redirect>
					{tabs.map((item, idx) => {
						return (
							<Route
								path={item.path}
								component={item.component}
								key={idx}
								// exact
							></Route>
						);
					})}
				</div>
				<Footer />
			</HashRouter>
		</div>
	);
};

export default observer(PcPage);
