import About from "src/Page/About";
import Contact from "src/Page/Contact";
import Home from "src/Page/Home";
import Product from "src/Page/Product";

export const tabs: { component?: React.FC; path: string; name: string }[] = [
	{ path: "/home", name: "首页", component: Home },
	{ path: "/about", name: "关于我们", component: About },
	// { path: "/case", name: "工程案例", component: Case },
	{ path: "/product", name: "产品中心", component: Product },
	// { path: "/cooperation", name: "合作厂商", component: Cooperation },
	// { path: "/skill", name: "技术指导" ,component:Skill},
	// { path: "/news", name: "相关资讯", component: News },
	{ path: "/contact", name: "联系我们", component: Contact },
];

export type PATH_TYPE = "/home" | "/about" | "/product" | "/contact";
export type PATH_NAME_TYPE = "首页" | "关于我们" | "产品中心" | "联系我们";
export enum PATH_CONFIG_TYPE {
	首页 = "/home",
	关于我们 = "/about",
	产品中心 = "/product",
	联系我们 = "/contact",
}
export const PATH_ENUM = {
	首页: "/home",
	关于我们: "/about",
	产品中心: "/product",
	联系我们: "/contact",
	华亚: "/huaya",
	三佑: "/sanyou",
	PPR: "/ppr",
};
export const PATHNAME_ENUM = {
	"/home": "首页",
	"/about": "关于我们",
	"/product": "产品中心",
	"/contact": "联系我们",
	"/huaya": "华亚",
	"/sanyou": "三佑",
	"/ppr": "PPR",
};
