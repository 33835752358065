const _window: any = window;

const CONFIG = _window.CONFIG;

// 系统参数配置(用户不可读)
export const DESIGN: Design = CONFIG.design;

// 地址配置
// export const API_SERVER = HOSTS[process.env.NODE_ENV || 'development'].API;
// export const OPS_SERVER = HOSTS[process.env.NODE_ENV || 'development'].ADMIN;
// export const LOCALHOST = `//${document.location.host}/`;
