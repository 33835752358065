import { observer } from "mobx-react";
import React from "react";
import { ProductConfig } from "src/Page/Product/ProductConfig";
import classNames from "./index.module.scss";

interface IProp {
	products?: { src?: string; img: string; title: string; key?: string }[];
	productKey: string;
	onClick?: (value: any) => void;
}
const Productdetail: React.FC<IProp> = (props) => {
	return (
		<div className={classNames.productdetail}>
			{ProductConfig.find((item) => item.key === props.productKey)?.list?.map(
				(product, index) => {
					return (
						<div
							// href={product.src}
							key={product.img ?? index}
							className={classNames.detial}
							onClick={() => {
								props.onClick && props.onClick(product.img);
							}}
							// target={""}
							// rel="noreferrer"
						>
							<img src={product.img} className={classNames.image}></img>
							<h3 className={classNames.title}>{product.title}</h3>
						</div>
					);
				},
			)}
		</div>
	);
};
export default observer(Productdetail);
