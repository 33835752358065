export const ImageConfig = [
	{
		imgName: "HOME-GS",
		taobaoUrl: "/product/huaya", //"https://shop63784486.taobao.com/category-1034154042.htm?spm=a1z10.1-c-s.w5002-22423835646.3.5ebc9085xRnz4I&search=y&catName=UPVC%B9%DC%B2%C4%BC%B0%C5%E4%BC%FE",
	},
	{
		imgName: "HOME-HG",
		taobaoUrl: "/product/sanyou",
	},
	{
		imgName: "HOME-FM",
		taobaoUrl: "/product/fm", // "https://shop63784486.taobao.com/category-1117094794.htm?spm=a1z10.5-c-s.w5002-22423835646.5.1ec06df9VkoVee&search=y&catName=%B7%A7%C3%C5",
	},
	{
		imgName: "HOME-CPVC",
		taobaoUrl: undefined,
	},
	{
		imgName: "HOME-PPR",
		taobaoUrl: "/product/ppr", // "https://shop63784486.taobao.com/category-1540307137.htm?spm=a1z10.5-c-s.w5002-22423835646.4.1ec06df9YEgfxj&search=y&catName=PPR%B9%DC%B2%C4%BC%B0%C5%E4%BC%FE",
	},
	{
		imgName: "HOME-PE",
		taobaoUrl: undefined,
	},
	{
		imgName: "HOME-PPH",
		taobaoUrl: undefined,
	},
];
