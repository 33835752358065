import { observer } from "mobx-react";
import React from "react";
import InfoBlock from "src/Component/InfoBlock";

const Introduction: React.FC = () => {
	return (
		<InfoBlock title={"公司简介"} titleEng={"ABOUT US"} fenge={true}>
			<div>
				<p>
					杭州如日机电设备有限公司系台湾台塑集团“华亚芜湖塑胶有限公司”，设在中国杭州地区总代理，公司位于杭州，主要负责浙江杭州地区的销售及售后服务，公司主要经营：PVC、PE、PPR给水管材、管件；PVC-U、PVC-M高尔夫球场
					专用管、喷灌专用配件、胶水、高尔夫专用电线电缆、PVC、HDPE双壁波纹管、透水管；PVC排水管材、管件；PVC电工套管。我们是一家集采购、销售、售后服务为一体的企业，产品库存丰富，供货准确迅速，我们始终坚持信誉第一、用户至上的经营宗旨，愿为各界新老客户提供最佳服务。
				</p>
				<br></br>
				<p>
					我们的主要客户群面向游泳馆工程；高速公路；高速铁路；绿化及农业灌溉工程；水处理工程、自来水工程、高尔夫球场喷灌工程；海水养殖；建筑工程；环保工程；电气、电力、电信工程；市政、等等。公司已先后为杭州地区80多处游泳馆、泳池提供管材管件，赢得业界一至好评，热诚欢迎各界新老朋友莅临指导、洽谈合作、共同发展。
				</p>
				<br></br>
				<p>
					杭州如日机电设备有限公司始终秉承“信誉第一，服务至上”的理念，永远以质量求生存，以信誉求发展，团结拼搏，锐意创新，与时俱进，使合肥市的水管行业的发展一年一个新台阶。在今后的时间里，广大员工将共同努力，进一步优化产品结构，提高产品能力和产品的科技含量，向规模大，品种全，质量好，价格低的优质产品进取。
				</p>
				<br></br>
				<p>
					杭州如日机电设备有限公司将一如既往的探求客户需求和服务的更高水准，将产品和服务有机的结合，力争满足客户的各种采购需求和应用提升,成为客户发展过程中的紧密合作伙伴。
				</p>
			</div>
		</InfoBlock>
	);
};
export default observer(Introduction);
