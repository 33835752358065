import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { observer } from "mobx-react";
import React, { CSSProperties, useRef } from "react";
import { Link } from "react-router-dom";
import { tabs } from "src/Config/tabs";
import HeaderStore from "src/Page/Header/HeaderStore";
import classNames from "./index.module.scss";

interface IProp {
	imgsUrlConfig: {
		imgUrl: string;
		taobaoUrl?: string;
	}[];
}
const Banner: React.FC<IProp> = (props) => {
	const ref = useRef<CarouselRef>(null);
	const contentStyle: CSSProperties = {
		// height: "160px",
		color: "#fff",
		// lineHeight: "160px",
		textAlign: "center",
		background: "#364d79",
		// width: "100%",
		// height: "max-content",
	};
	return (
		<div className={classNames.Banner}>
			<span
				className={`iconfont icon-jiantou ${classNames.arrow} ${classNames.left}`}
				onClick={() => {
					ref.current?.prev();
				}}
			></span>
			<Carousel
				autoplay
				// className={classNames.carousel}
				// style={{ height: "max-content", width: "100%" }}
				ref={ref}
			>
				{props.imgsUrlConfig.map((imgUrl, index) => {
					return (
						<div className={classNames.imgBlock} key={index}>
							<Link
								to={imgUrl.taobaoUrl ?? "/home"}
								onClick={() => {
									HeaderStore.setActive(
										tabs.find((tab) => tab.path === "/product")?.path ??
											"/home",
									);
								}}
							>
								<img
									key={index}
									className={classNames.img}
									style={contentStyle}
									src={imgUrl.imgUrl}
								></img>
							</Link>
							{/* <a
								target={imgUrl.taobaoUrl ? "_blank" : "_self"}
								rel="noreferrer"
								href={imgUrl.taobaoUrl ? imgUrl.taobaoUrl : "javaScript:void(0);"}
							>
								<img
									key={index}
									className={classNames.img}
									style={contentStyle}
									src={imgUrl.imgUrl}
								></img>
							</a> */}
						</div>
					);
				})}
			</Carousel>
			<span
				className={`iconfont icon-jiantou ${classNames.arrow} ${classNames.right}`}
				onClick={() => {
					ref.current?.next();
				}}
			></span>
		</div>
	);
};
export default observer(Banner);
