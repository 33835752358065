export const ProductConfig = [
	{
		key: "huaya",
		list: [
			{
				img: "/img/Product/HY/管材.jpg",
				title: "管材",
				detial: {
					size: "",
					instructions: "管材",
				},
			},
			{
				img: "/img/Product/HY/弯头.jpg",
				title: "弯头",
				detial: {
					size: "",
					instructions: "弯头",
				},
			},
			{
				img: "/img/Product/HY/45°弯头.jpg",
				title: "45°弯头",
				detial: {
					size: "",
					instructions: "45°弯头",
				},
			},
			{
				img: "/img/Product/HY/直接.jpg",
				title: "直接",
				detial: {
					size: "",
					instructions: "直接",
				},
			},
			{
				img: "/img/Product/HY/三通.jpg",
				title: "三通",
				detial: {
					size: "",
					instructions: "三通",
				},
			},
			{
				img: "/img/Product/HY/内丝直接.jpg",
				title: "内丝直接",
				detial: {
					size: "",
					instructions: "内丝直接",
				},
			},
			{
				img: "/img/Product/HY/外丝直接.jpg",
				title: "外丝直接",
				detial: {
					size: "",
					instructions: "外丝直接",
				},
			},
			{
				img: "/img/Product/HY/活接.jpg",
				title: "活接",
				detial: {
					size: "",
					instructions: "活接",
				},
			},
			{
				img: "/img/Product/HY/大小头.jpg",
				title: "大小头",
				detial: {
					size: "",
					instructions: "大小头",
				},
			},
			{
				img: "/img/Product/HY/法兰.jpg",
				title: "法兰",
				detial: {
					size: "",
					instructions: "法兰",
				},
			},
			{
				img: "/img/Product/HY/管帽.jpg",
				title: "管帽",
				detial: {
					size: "",
					instructions: "管帽",
				},
			},
		],
	},
	{
		key: "sanyou",
		list: [
			{
				img: "/img/Product/SY/管材.jpg",
				title: "管材",
				detial: {
					size: "",
					instructions: "管材",
				},
			},
			{
				img: "/img/Product/SY/管材-加厚.jpg",
				title: "管材-加厚",
				detial: {
					size: "",
					instructions: "管材-加厚",
				},
			},
			{
				img: "/img/Product/SY/45°弯头.jpg",
				title: "45°弯头",
				detial: {
					size: "",
					instructions: "45°弯头",
				},
			},
			{
				img: "/img/Product/SY/直接.jpg",
				title: "直接",
				detial: {
					size: "",
					instructions: "直接",
				},
			},
			{
				img: "/img/Product/SY/三通.jpg",
				title: "三通",
				detial: {
					size: "",
					instructions: "三通",
				},
			},
			{
				img: "/img/Product/SY/补芯.jpg",
				title: "补芯",
				detial: {
					size: "",
					instructions: "补芯",
				},
			},
			{
				img: "/img/Product/SY/法兰.jpg",
				title: "法兰",
				detial: {
					size: "",
					instructions: "法兰",
				},
			},
			{
				img: "/img/Product/SY/管卡.jpg",
				title: "管卡",
				detial: {
					size: "",
					instructions: "管卡",
				},
			},
			{
				img: "/img/Product/SY/管帽.jpg",
				title: "管帽",
				detial: {
					size: "",
					instructions: "管帽",
				},
			},
			{
				img: "/img/Product/SY/活接.jpg",
				title: "活接",
				detial: {
					size: "",
					instructions: "活接",
				},
			},
			{
				img: "/img/Product/SY/盲板.jpg",
				title: "盲板",
				detial: {
					size: "",
					instructions: "盲板",
				},
			},
			{
				img: "/img/Product/SY/内丝.jpg",
				title: "内丝",
				detial: {
					size: "",
					instructions: "内丝",
				},
			},
			{
				img: "/img/Product/SY/外丝.jpg",
				title: "外丝",
				detial: {
					size: "",
					instructions: "外丝",
				},
			},
			{
				img: "/img/Product/SY/弯头.jpg",
				title: "弯头",
				detial: {
					size: "",
					instructions: "弯头",
				},
			},
			{
				img: "/img/Product/SY/异四通.jpg",
				title: "异径四通",
				detial: {
					size: "",
					instructions: "异径四通",
				},
			},
			{
				img: "/img/Product/SY/正四通.jpg",
				title: "正四通",
				detial: {
					size: "",
					instructions: "正四通",
				},
			},
		],
	},
	{
		key: "PPR",
		list: [
			{
				img: "/img/Product/PPR/45°弯头.jpg",
				title: "45°弯头",
				detial: {
					size: "",
					instructions: "45°弯头",
				},
			},
			{
				img: "/img/Product/PPR/大小头.jpg",
				title: "大小头",
				detial: {
					size: "",
					instructions: "大小头",
				},
			},
			{
				img: "/img/Product/PPR/法兰.jpg",
				title: "法兰",
				detial: {
					size: "",
					instructions: "法兰",
				},
			},
			{
				img: "/img/Product/PPR/管帽.jpg",
				title: "管帽",
				detial: {
					size: "",
					instructions: "管帽",
				},
			},
			{
				img: "/img/Product/PPR/过桥弯.jpg",
				title: "过桥弯",
				detial: {
					size: "",
					instructions: "过桥弯",
				},
			},
			{
				img: "/img/Product/PPR/截止阀.jpg",
				title: "截止阀",
				detial: {
					size: "",
					instructions: "截止阀",
				},
			},
			{
				img: "/img/Product/PPR/内丝三通.jpg",
				title: "内丝三通",
				detial: {
					size: "",
					instructions: "内丝三通",
				},
			},
			{
				img: "/img/Product/PPR/内丝弯头.jpg",
				title: "内丝弯头",
				detial: {
					size: "",
					instructions: "内丝弯头",
				},
			},
			{
				img: "/img/Product/PPR/内丝直接.jpg",
				title: "内丝直接",
				detial: {
					size: "",
					instructions: "内丝直接",
				},
			},
			{
				img: "/img/Product/PPR/双联弯头.jpg",
				title: "双联弯头",
				detial: {
					size: "",
					instructions: "双联弯头",
				},
			},
			{
				img: "/img/Product/PPR/外丝弯头.jpg",
				title: "外丝弯头",
				detial: {
					size: "",
					instructions: "外丝弯头",
				},
			},
			{
				img: "/img/Product/PPR/外丝直接.jpg",
				title: "外丝直接",
				detial: {
					size: "",
					instructions: "外丝直接",
				},
			},
			{
				img: "/img/Product/PPR/弯头.jpg",
				title: "弯头",
				detial: {
					size: "",
					instructions: "弯头",
				},
			},
			{
				img: "/img/Product/PPR/异三通.jpg",
				title: "异三通",
				detial: {
					size: "",
					instructions: "异三通",
				},
			},
			{
				img: "/img/Product/PPR/正三通.jpg",
				title: "正三通",
				detial: {
					size: "",
					instructions: "正三通",
				},
			},
			{
				img: "/img/Product/PPR/直接.jpg",
				title: "直接",
				detial: {
					size: "",
					instructions: "直接",
				},
			},
		],
	},
	{
		key: "FM",
		list: [
			{
				img: "/img/Product/FM/电动蝶阀.jpg",
				title: "电动蝶阀",
				detial: {
					size: "",
					instructions: "电动蝶阀",
				},
			},
			{
				img: "/img/Product/FM/电动球阀.jpg",
				title: "电动球阀",
				detial: {
					size: "",
					instructions: "电动球阀",
				},
			},
			{
				img: "/img/Product/FM/法兰球阀.jpg",
				title: "法兰球阀",
				detial: {
					size: "",
					instructions: "法兰球阀",
				},
			},
			{
				img: "/img/Product/FM/法兰底阀.jpg",
				title: "法兰底阀",
				detial: {
					size: "",
					instructions: "法兰底阀",
				},
			},
			{
				img: "/img/Product/FM/翻板式止回阀.jpg",
				title: "翻板式止回阀",
				detial: {
					size: "",
					instructions: "翻板式止回阀",
				},
			},
			{
				img: "/img/Product/FM/普通球阀.jpg",
				title: "普通球阀",
				detial: {
					size: "",
					instructions: "普通球阀",
				},
			},
			{
				img: "/img/Product/FM/气动蝶阀.jpg",
				title: "气动蝶阀",
				detial: {
					size: "",
					instructions: "气动蝶阀",
				},
			},
			{
				img: "/img/Product/FM/铅壳电动球阀.jpg",
				title: "铅壳电动球阀",
				detial: {
					size: "",
					instructions: "铅壳电动球阀",
				},
			},
			{
				img: "/img/Product/FM/球形底阀.jpg",
				title: "球形底阀",
				detial: {
					size: "",
					instructions: "球形底阀",
				},
			},
			{
				img: "/img/Product/FM/球形止回阀.jpg",
				title: "球形止回阀",
				detial: {
					size: "",
					instructions: "球形止回阀",
				},
			},
			{
				img: "/img/Product/FM/取样阀.jpg",
				title: "取样阀",
				detial: {
					size: "",
					instructions: "取样阀",
				},
			},
			{
				img: "/img/Product/FM/手柄式蝶阀.jpg",
				title: "手柄式蝶阀",
				detial: {
					size: "",
					instructions: "手柄式蝶阀",
				},
			},
			{
				img: "/img/Product/FM/双活结球阀.jpg",
				title: "双活结球阀",
				detial: {
					size: "",
					instructions: "双活结球阀",
				},
			},
			{
				img: "/img/Product/FM/涡轮式蝶阀.jpg",
				title: "涡轮式蝶阀",
				detial: {
					size: "",
					instructions: "涡轮式蝶阀",
				},
			},
			{
				img: "/img/Product/FM/Y型过滤器.jpg",
				title: "Y型过滤器",
				detial: {
					size: "",
					instructions: "Y型过滤器",
				},
			},
		],
	},
];
