import { observer } from "mobx-react";
import React from "react";
import AdressMap from "src/Component/AdressMap";
import Company from "src/Component/Company";
import Content from "src/Component/Content";
import InfoBlock from "src/Component/InfoBlock";
import SideBar from "src/Component/SideBar";
import classNames from "./index.module.scss";

const menus = [
	{
		name: "联系方式",
		str: "contact",
	},
];
const Infos = [
	{
		icon: "icon-qiyexinxi",
		title: "公司名称",
		value: "杭州如日机电设备有限公司",
		import: true,
		color: "#003399",
	},
	{
		icon: "icon-shouji",
		title: "电 话 ",
		value: "0571-89993478",
		color: "#E53333",
		number: true,
	},
	{
		icon: "icon-shouji",
		title: "手 机 ",
		value: "13857110381",
		color: "#E53333",
		number: true,
	},
	// {
	// 	icon: "icon-gongsi",
	// 	title: "邮箱",
	// 	value: "1805567389@qq.com",
	// },
	{
		icon: "icon-dingwei",
		title: "地 址 ",
		value: "杭州市江干区德胜东路2777号金茂五金广场东北角精品馆V-02号(塑料管道阀门)",
	},
];
const Contact: React.FC = () => {
	return (
		<div className={classNames.contact}>
			<Company></Company>
			<Content>
				<SideBar
					default={false}
					title="联系我们"
					menus={menus}
					key={Math.random() * Math.random()}
				></SideBar>
				<InfoBlock title={"联系我们"} titleEng={"CONTENT US"}>
					<div className={classNames.infos}>
						{Infos.map((info, index) => {
							return (
								<div className={classNames.infoBlock} key={index}>
									<div
										className={`${classNames.title} ${
											info.import && classNames.import
										}`}
									>
										{info.import ?? info.title + ": "}
										<span style={{ color: info.color }}>
											{info.number ? (
												<strong>{info.value}</strong>
											) : (
												info.value
											)}
										</span>
									</div>
								</div>
							);
						})}
					</div>
					<AdressMap className={classNames.map}></AdressMap>
				</InfoBlock>
			</Content>
			{/* <Banner imgsUrl={["/img/Home/1.jpg", "/img/Home/1.jpg", "/img/Home/1.jpg"]} /> */}
		</div>
	);
};
export default observer(Contact);
