import { Image } from "antd";
import { observer } from "mobx-react";
import React from "react";
import classNames from "./index.module.scss";

const Company: React.FC = () => {
	return (
		<div className={classNames.image}>
			<Image src="/img/About/about.jpg" preview={false}></Image>
			<div className={classNames.list}>
				<Image src="/img/About/company.jpg" preview={false} width={"20vh"} />
				<Image src="/img/About/company.jpg" preview={false} width={"20vh"} />
			</div>
		</div>
	);
};
export default observer(Company);
